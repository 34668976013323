import React, { Component} from 'react';



//Material UI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ClientDocuments } from '../Business/ClientDocuments';
import { SelectList } from '../Utilities/SelectList';
//Database Services
import { getClientUserData, isAdminUser, getClients, getClientDocs } from '../../DataServices/BusinessService'
import { getEnums } from '../../DataServices/Utility'
import { getPublicAttributes, getUserList, getFeedPreferences, setFeedPreferences } from '../../DataServices/UserService'



export class Home extends Component {

    constructor(props) {
        super(props);
        this.props.setToken.bind(this);
        this.state = {
            documentFolders: [],      
            contentUser: false,
            contentLoaded: false,
            dropDownOptions: [],
            selectedContentType: null,
            selectedContentProps: null,
            contentUser: true,
            pauseRefreshes: false,
            bottomMenuValue: 0,
            contentLoading: false,
            height: 0,
            width: 0,
            //selectedClient: { label: "Test Personal", value: "Test Personal" }
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.getData();

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    //Data Fetching
    getData = async () => {
        const isAdmin = await isAdminUser(this.props.user.userID, this.props.businessID);
        this.setState({ isAdmin });
        this.props.setIsAdmin(isAdmin);
       // this.getClientData();
        if (isAdmin === true) {
            this.getClientList();
        }
        else {
            this.getClientData();
        }

    }
    getDropdownOptions = async () => {
        const data = await getEnums();
        this.setState({ dropDownOptions: data });
    }
    getClientList = async () => {
        const clientData = await getClients(this.props.businessID);
        const clientList = [];
      
        if (clientData && clientData.length > 0) {
            for (var i = 0; i < clientData.length; i++) {
                clientList.push({ label: clientData[i].name, value: clientData[i].id });
            }
            this.setState({ clientData, clientList, selectedClient: clientData[0].id, selectedClientIDX: 0, selectedClientName: clientData[0].name });


            if (clientData[0] && clientData[0].id) {
                this.setCurrentFolders(clientData[0].id, clientData);
            }
        }
    }
    getUserList = async () => {
        const data = await getUserList();
        const userList = [];

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                userList.push({ id: data[i].userID, display: data[i].firstName + ' ' + data[i].lastName });
            }
        }
        this.setState({ userList });
    }
    getClientData = async () => {
        const clientData = await getClientUserData();
        const clientList = [];
        if (clientData && clientData.length > 0) {
            for (var i = 0; i < clientData.length; i++) {
                clientList.push({ label: clientData[i].name, value: clientData[i].id });
            }
            this.setState({ clientData, clientList, selectedClient: clientData[0].id, selectedClientIDX: 0, selectedClientName: clientData[0].name });


            if (clientData[0] && clientData[0].id) {
                this.setCurrentFolders(clientData[0].id, clientData);
            }
        }

    }

    setCurrentFolders = (clientID, clientData) => {
        if (clientID) {
            if (this.state.clientData) {
                var client = this.state.clientData.find(c => {
                    return c.id === clientID
                })
            }
            else {
                var client = clientData.find(c => {
                    return c.id === clientID
                })
            }
            const documentFolders = [];
            if (client) {
                if (client.documentFolders && client.documentFolders.length > 0) {
                    for (var j = 0; j < client.documentFolders.length; j++) {
                        if (client.documentFolders[j] && client.documentFolders[j].id) {
                            documentFolders.push(client.documentFolders[j]);
                        }
                    }
                }
            }
            this.setState({ documentFolders });


        }


    }
    handleClientChange = (e) => {

        this.setCurrentFolders(e);

        var client = this.state.clientData.find(c => {
            return c.id === e
        })

        this.setState({ selectedClient: e, selectedClientName : client.name});
    }
    updateFolders = (documentFolders) => {

        this.setState({ documentFolders });

    }
    selectClient = async (clientID) => {
        if (clientID) {
            var client = this.state.clientData.find(c => {
                return c.id === clientID.value
            })

            const documentFolders = await getClientDocs(clientID.value);

            this.setState({ documentFolders, selectedClient: clientID.value, selectedClientName: client.name });
        }
        else {
            const documentFolders = [];

            this.setState({ documentFolders, selectedClient: null, selectedClientName: null });
        }

        
    }
    handleManageClientUsers = () => { }
    handleEditClient = () => { }

    clientSelect = () => {
        if (this.state.clientList) {
            return (
                <Autocomplete
                    loadingText="Loading Clients"
                    onChange={(e, val) => this.selectClient(val) }
                    disablePortal

                    options={this.state.clientList}
                    sx={{
                        minWidth: 300,
                        height: "36px",
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                                    fontSize: ".8rem"                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Start Typing Name"
                            sx={{
                                color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                                fontSize: ".8rem"
} }

                        />}
                />
                
                
                )
        }
    }
    adminTools = () => {
        return (
            <Stack direction="row" spacing={2} sx={{ mx: 1, width:"-webkit-fill-available" }}>
                {this.clientSelect()}

     
                {(this.state.documentFolders && this.state.documentFolders.length > 0) &&
                    <>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="newdoc"
                            onClick={this.handleEditClient}
                        >
                            Edit Client
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="newdoc"
                            onClick={this.handleManageClientUsers}
                        >
                            Manage Client Users
                        </Button>
                    </>
                }
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    aria-label="newclient"
                    onClick={() => this.setState({showNewClient: true })}
                >
                    Add New Client
                </Button>
            </Stack>
        )

    }
    render() {
        return (
            <Box sx={{ pt: { xs: 7, sm: 8 } }} className={this.props.dark && "dark"} >
                {(this.state.clientData) &&
                    <Stack>
                        <Stack direction="row" spacing={2} sx={{ mx: 2, py:1}}>
                            <Typography  sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite, marginTop: "auto", marginBottom: "auto" }}>
                                {this.state.isAdmin ? "Select Client:" : "Select Account"}
                            </Typography>
                            {this.state.isAdmin ? this.adminTools()

                                :

                                <SelectList
                                    dark={this.props.dark}
                                    ckey="clientselect"

                                    value={this.state.selectedClient}
                                    onChange={this.handleClientChange}
                                    listItems={this.state.clientList}
                                    width="fit-content"
                                    minWidth="150px"
                                />
                            }

                        </Stack>
        {/*                {(this.state.documentFolders && this.state.documentFolders.length > 0) &&*/}
                            <ClientDocuments
                            dark={this.props.dark}
                            user={this.props.user}
                            clientID={this.state.selectedClient}
                            clientName={this.state.selectedClientName}
                            appIdentifier={"ls"}
                            profile={this.props.profile}
                            userList={this.props.userList}
                            folders={this.state.documentFolders}
                            updateFolders={this.updateFolders}
                            isAdmin={this.state.isAdmin }
                            />
                       {/* }*/}
                    </Stack>
                }
            </Box>
        )
    }
}
