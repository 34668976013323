import React, { Component } from 'react';

//Custom Components
import { Spin } from '../Common/Utilities/Spin';
import { ModalDialog } from '../Common/Utilities/ModalDialog'
import { DocumentViewer } from '../Common/Content/Documents/DocumentViewer';
import { ContactForm } from './ContactForm';
import Login from './Login'
import { Socials } from './Socials'
import { SliderComponent } from './SliderComponent'

import { Box, Stack, Grid, Tooltip } from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { Helmet } from "react-helmet";
import Linkify from 'react-linkify';


//Database Services
import { getPage, getContactNotificationRecipients} from '../DataServices/WebPageService'
import { getPhotoThumb } from '../DataServices/PhotoService'
import { getBlog } from '../DataServices/BlogService'
import { getEventInfo } from '../DataServices/EventService'
import { getSiteData } from '../DataServices/SiteDataService'
import { getDocument, } from '../DataServices/DocumentService';

export class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledDown: false,
            showContactForm: false,
            showLogin: false,
            showDocumentViewer: false,
            height: 0,
            width: 0,
            anchorEl: null,
        }
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getSiteData();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    getSiteData = async () => {
        if (this.props.siteID) {
            const site = await getSiteData(this.props.siteID);
            if (site) {
                this.setState({ site });
                document.title = site.pageTitle;
                await this.getContactNotificationRecipients();
            }
            else {
                this.setState({ loadError: true });
            }
        }
        this.setState({ loadError: true });
    }
    getContactNotificationRecipients = async () => {
        const notificationRecipients = await getContactNotificationRecipients(this.props.siteID);
        if (notificationRecipients) {
            this.setState({ notificationRecipients });
        }
    }
    //getDocument = async (item) => {

    //    if (doc && doc.eFiles && doc.eFiles.length > 0 && doc.eFiles[0].fileContent) {
    //        this.setState({
    //            docName: item.name,
    //            docContent: doc.eFiles[0].fileContent,
    //            showDocumentViewer: true,
    //        });
    //    }
    //}

    //Data fetchers
    getWebPage = async () => {
        const data = await getPage(this.props.webPageID);
        if (data === 'error') {
            this.props.setToken('');
            return;
        }
        if (data) {
            this.setState({ webPage: data });
            this.loadPageContent();
        }

    }
    loadPageContent = async () => {
        if (this.state.webPage && this.state.webPage.sections && this.state.webPage.sections.length > 0) {
            for (var i = 0; i < this.state.webPage.sections.length; i++) {
                let section = this.state.webPage.sections[i];
                if (section.content && section.content.length > 0) {
                    for (var j = 0; j < section.content.length; j++) {
                        let content = section.content[j];
                        if (content.refID) {
                            const type = content.contentTypeID;
                            switch (type) {
                                case 4:
                                    const data = await getBlog(content.refID);
                                    if (data) {
                                        const loadedData = await this.loadBlogSpecificContent(data);
                                        const webPage = this.state.webPage;
                                        webPage.sections[i].content[j].blogContent = loadedData;
                                        this.setState({ webPage });

                                    }
                                    break;
                                case 8:

                                    const edata = await getEventInfo(content.refID);

                                    if (edata) {
                                        const eloadedData = await this.loadEventSpecificContent(edata);
                                        const ewebPage = this.state.webPage;
                                        ewebPage.sections[i].content[j].eventContent = eloadedData;
                                        this.setState({ webPage: ewebPage });

                                    }
                                    break;
                                default:
                                    break;

                            }
                        }

                    }

                }
            }
        }
    }

    //Event Handlers
    handleClickContent = (sectionIDX, contentIDX, href, document) => {
        if (href) {
            window.open(href, '_blank');
        }
        //else {
        //    if (document) {
        //        this.getDocument(document);
        //    }
        //}
        //else {
        //    const content = this.state.webPage.sections[sectionIDX].content[contentIDX];
        //    if (content) {
        //        const type = content.contentTypeID;
        //        switch (type) {
        //            case 4:
        //                this.setState({
        //                    selectedContent: content,
        //                    showBlogContent: true
        //                });
        //                break;
        //            case 8:
        //                this.setState({
        //                    selectedContent: content,
        //                    showEventContent: true
        //                });
        //                break;
        //            default:
        //                break;
        //        }
        //    }
        //}

    }
    handleClientPortal = (e) => {
        this.setState({ showLogin: true });
    }
    handleClickFloatingContactButton = (event) => {
        this.setState({ anchorEl : event.currentTarget })
    }
    //Render Funcs
    header = (section) => {
        return (
            <Box key={section.id} sx={{ position: section.fixed ? "fixed" : undefined, pr: 2, display: "flex", width: "100%", background: section.background, boxShadow: section.shadow && 8, zIndex: section.shadow && 10000 }}>
                {section.actionButton &&
                    <Box sx={{ width: "150px" }} />
                }
        
                <Box display="flex" sx={{ maxWidth: "1440px", marginLeft: section.logoCentered && "auto", marginRight: "auto", }}>
                    {section.logo ? 
                        <img src={section.logo} alt={section.alt} style={{ width: "100%", maxHeight: section.logoHeight, height: "auto", objectFit: "contain", margin: section.logoCentered && "auto" }} />
                    :
                        <Typography align="center" variant="h2" sx={{ fontFamily: this.state.site.fontFamily, lineHeight: 1, m: 3, color:  theme => theme.palette.common.white, marginLeft:  "auto", marginRight: "auto", }}>
                            {this.state.site.pageTitle}
                        </Typography>
                    }
                </Box>
                
                {section.actionButton &&
                    <>
                        {section.actionButton.type === "contact" ?
                        <Button  sx={{ mt: "auto", mb: "auto", width: "150px", color: section.actionButton.color }} onClick={() => this.setState({ showContactForm: true })}>{section.actionButton.label}</Button>
                            :
                            <Button onClick={this.handleClientPortal} sx={{ mt: "auto", mb: "auto", width: "150px", color: section.actionButton.color }}>{section.actionButton.label}</Button>
                        }

                    </>

                }
            </Box>
        )
    }
    main = (section) => {
        return (
            <Stack
                key={section.id}
                id="mainsection"
                sx={{
                    background: section.background.color,
                    boxShadow: 8,
                    zIndex: 900,
                    height: "100%",
                    backgroundImage: section.background.image && 'url(' + section.background.image + ')',
                    backgroundSize: section.background.image && "cover",
                    backgroundRepeat: section.background.image && "no-repeat",
                    backgroundPosition: "center",
                    backgroundPositionY: section.background.verticalPosition ?? "top",
                    filter: section.background.filter ?? undefined,
                    minHeight: section.background.fullHeight ? "100vh" : section.background.height ?? "600px",
                    display:"flex",
                    justifyContent: section.contentAlignment ?? "center",
                }}>
                {section.header &&
                    <Box display="flex" sx={{ width: "100%", background: section.header.background, }}>
                        {section.header.actionButton &&
                            <Box sx={{ width: "150px" }} />
                        }
                        {section.header.titleImage ?
                            <Box display="flex" sx={{ margin: "auto" }}>
                                <img src={section.header.titleImage} alt={section.header.alt} style={{ maxHeight: section.header.titleImageHeight, width: section.header.titleImageCentered && "100%", height: "auto", objectFit: "contain", margin: "auto" }} />
                            </Box>
                            :
                            <Typography align="center" variant="h1" sx={{ lineHeight: 1, m: 3, color: section.slider.darkText ? theme => theme.palette.common.black : theme => theme.palette.common.white, marginLeft: section.header.titleImageCentered && "auto", marginRight: "auto", }}>
                                {this.state.site.pageTitle}
                            </Typography>
                        }
                        {section.header.actionButton &&
                            <Tooltip title={section.header.actionButton.tooltip} >

                                    <Button variant="contained" sx={{ my: "auto", mr: 2, width: "150px", color: section.header.actionButton.color, background: section.header.actionButton.background }} onClick={() => this.setState({ showContactForm: true })}>{section.header.actionButton.label}</Button>
   
                            </Tooltip>

                        }
                    </Box>
                }
                {section.slider && section.slider.size === "compact" && this.spacer(64)}
                {section.slider &&
                    <Stack
                        sx={{
                            py: { xs: 2, sm: section.slider.topPad ?? 6 },
                            px: { xs: 2, sm: undefined},
                            my: !section.slider.bottom ? "auto" : undefined,
                            mt: section.slider.bottom ? "auto" : undefined,
                            mb: section.slider.bottom ? 0 : undefined,
                            mx: { xs: 0, sm: "auto" },
                            width: { xs:"100%", sm: undefined},
                            maxWidth: { xs: "100%", sm: section.slider.size === "compact" ? "1600px" : undefined }
                        }}
                        className={section.slider.size === "compact" && "compact"}
                    >
                        <SliderComponent slider={section.slider} fontFamily={this.state.site.fontFamily} />
                        {section.contactButton && this.contactUsButton()}

                    </Stack>
                }
        
                {section.contactInfo &&
                    <Box sx={{ position: "relative", bottom: 0 }}>
                        {this.contactInfoSub()}
                    </Box>

                }
            </Stack>
        )
    }
    contactInfoSub = () => {
        const section = this.state.site.sections.find(x => x.type === "contactinfo");
       
        if (section) {
            section.titleColor = "#fff";
            return (
                <Box key={section.id} display="flex" sx={{ minHeight: "76px", backgroundColor: section.background, opacity: { xs: .7}, pb:1 }} >
                    <Stack sx={{ width: "100%" }}>
                        <Grid container spacing={2} sx={{ maxWidth: "1440px", my: 0, mx: "auto" }}>
                            {section.officeHours &&
                                <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                    <Stack sx={{ px: { xs: 2, }, width: { xs: "100%", md: undefined }, m: "auto" }}>
                                        <Typography key="cisoh" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: { xs: "1rem", md: "1.1rem" } , color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                            {section.officeHours.title}
                                        </Typography>
                                        {section.officeHours.items.map(x => {
                                            return (
                                                <Typography key={x} variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {x}
                                                </Typography>
                                            )
                                        }
                                        )}

                                    </Stack>
                                </Grid>
                            }
                            {section.flatlayout &&
                                <>
                                    {
                                        section.address &&
                                        <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
   
                                            <Typography key="ciradd" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {"Address: " + section.address.street + ", " + section.address.city + ", " + section.address.state + "  " + section.address.zip}
                                                </Typography>

                                        </Grid>
                                }
                                {
                                    section.phone &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                            <Typography key="cirpn" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {"Phone: " + section.phone}
                                            </Typography>
                                    </Grid>
                                }
                                {
                                    section.email &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                            <Linkify key="ciraelfy" componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key + "asdf"}> {decoratedText} </a>)}>
                                                <Typography key="cirae" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {"Email: " + section.email}
                                                </Typography>
                                            </Linkify>
                                    </Grid>
                                }
                                </>
   
                            }
                            {!section.flatlayout &&
                                <>
                                    {section.address &&
                                        <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                            <Stack sx={{ px: { xs: 2, }, m: "auto" }}>
                                                <Typography key="ciradd" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: { xs: "1rem", md: "1.1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    Address:
                                                </Typography>
                                                <Typography key="ciradd2" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {section.address.street}
                                                </Typography>
                                                <Typography key="ciradd3" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {section.address.city + ", " + section.address.state + "  " + section.address.zip}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                        <Stack sx={{ px: { xs: 2, }, m: "auto" }}>
                                            {section.phone &&
                                                <Typography key="cirpn" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {"Phone: " + section.phone}
                                                </Typography>
                                            }
                                            {section.email &&
                                                <Linkify key="ciraelfy" componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key + "asdf"}> {decoratedText} </a>)}>
                                                    <Typography key="cirae" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                        {"Email: " + section.email}
                                                    </Typography>
                                                </Linkify>
                                            }
                                        </Stack>
                                    </Grid>

                                </>
                            }
                        </Grid>
                    </Stack>
                </Box>
                )

        }
        return null;
    }
    card = (item, index, col, height) => {
        return (
            <Box key={"csc" + index} component={Grid} item xs={12} sm={6} lg={col} display={{ xs: "block" }} sx={{ height: "100%" }}>
                <Card
                    elevation={16}
                    sx={{
                        maxWidth: 500,
                        marginLeft: "auto",
                        marginRight: "auto",
                        transition: "transform .2s",
                        "&:hover": { transform: "scale(1.05)" }
                    }}
                >
                    <CardActionArea onClick={() => this.handleClickContent(item.sectionIDX, item.contentIDX, item.href ?? null, item.document ?? null)}>
                        {item.image &&
                            <CardMedia
                                component="img"
                                height={height ?? 260}
                                image={item.image}
                                alt={item.primary}
                            />
                        }
                        <CardContent sx={{ py: item.image ? 1 : 2 , px:2, minHeight: (item.secondary || item.image) ? undefined : "100px", display: item.secondary ? undefined : "flex"}}>
                            <Typography key={item.primary + index} align="center" gutterBottom={item.secondary ? true : false} component="div" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 500, fontSize: item.secondary ?  "1.1rem" : "1.25rem", m: "auto" }}>
                                {item.primary}
                            </Typography>
                            {item.secondary &&
                                <Typography key={item.secondary + index} align="center" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem" }}>
                                    {item.secondary}
                                </Typography>
                            }
                        </CardContent>
                    </CardActionArea>
                    {/*<CardActions sx={{ justifyContent: "right" }}>*/}
                    {/*    <Button onClick={() => this.handleClickContent(item.sectionIDX, item.contentIDX)} size="small">Example Content</Button>*/}
                    {/*</CardActions>*/}
                </Card>
            </Box>
        )
    }
    cards = (section) => {
        let col = 3;
        if (section.items.length == 2)
            col = 6;
        if (section.items.length == 3)
            col = 4;
        if (section.maxRowSize) {
            col = section.maxRowSize;
        }
        return (
            <Box key={section.id} display="flex" sx={{ background: section.background, padding: "48px", boxShadow: 8, zIndex: section.shadow ? 400 : 200 }} >
                <Stack sx={{ width: "100%", maxWidth: "1440px", margin: "auto" }}>
                    {section.title &&
                        <Typography variant={this.state.site.compact ? "h3" : "h2"} sx={{ fontFamily: this.state.site.fontFamily, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, textAlign: "center", marginBottom: this.state.site.compact ? 2 : 5 }}>
                            {section.title}
                        </Typography>
                    }
                    {section.subtitle &&
                        <Typography variant="h5" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 500, marginLeft: "auto", marginRight: "auto", maxWidth: "1200px", color: section.subtitleColor, textAlign: "center", marginBottom: 7 }}>
                            {section.subtitle}
                        </Typography>
                    }
                    {this.spacer(24)}
                    <Grid container spacing={4}>
                        {section.items.map((item, index) => {
                            return (this.card(item, index, col, section.imageHeight))
                        })}
                    </Grid>
                    {section.contactButton && this.staticSpacer(80)}
                    {section.contactButton &&
                        <Box display="flex" sx={{ textAlign: "center", margin: "auto", display: { xs: "flex", md: section.contactButtonMobileOnly ? "none" : "flex" }  }}>
                            <Button
                                variant="contained"
                                onClick={() => this.setState({ showContactForm: true })}
                                sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 400, borderRadius: 1, fontSize: "1.1rem", margin: "auto", p: 2, background: section.contactButtonBackground, color: section.contactButtonColor }}
                            >
                                {this.state.site.contactButtonLabel}
                            </Button>
                        </Box>
                    }
                    {this.staticSpacer(48)}
                </Stack>
            </Box>
        )
    }
    checkList = (list) => {
        return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {list.listItems.map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                        <ListItem
                            key={value}
                            disablePadding
                        >
                            <ListItemButton role={undefined}  dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={false}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            )
    }
    socials = (section) => {
        return (
            <Socials key={section.id} socials={section} compact={this.state.site.compact} />
        )
    }
    partner = (section) => {
        return (
            <Box key={section.id} sx={{ width: "100%", background: section.background, pt: this.state.site.compact ? 1 : 3, pb: this.state.site.compact ? 1 : 3, boxShadow: section.shadow ? 8 : 0, zIndex: 1000, borderTop: section.partner.border && "1px solid #eaeaea" }}>
                <Box display="flex" sx={{ maxWidth: "1440px", marginLeft: "auto", marginRight: "auto", p: 2 }}>
                    <Stack sx={{ m: "auto", }}>
                        {section.disabled ?
                            <Typography sx={{ fontFamily: this.state.site.fontFamily, fontSize: "0.8rem", color: section.titleColor ? section.titleColor : "unset", textAlign:"center" }}>
                                {section.title}
                            </Typography>
                            :
                            <Button onClick={this.handleClientPortal} sx={{ fontFamily: this.state.site.fontFamily, fontSize: "0.8rem", color: section.titleColor ? section.titleColor : "unset", }}>
                                {section.title}
                            </Button>
                        }
                        <Button className="hoverLight" variant="contained" color="secondary" target="_blank" href={section.partner.href} sx={{ fontFamily: this.state.site.fontFamily, boxShadow: section.partner.shadow ? 8 : 0, background: section.partner.background, m: "auto", transition: "transform .3s", "&:hover": { transform: "scale(1.05)", background: section.partner.background, boxShadow: section.partner.shadow ? 8 : 0, }, }}>
                            <img src={section.partner.logo} alt={section.partner.alt} style={{ width: "auto", maxHeight: section.partner.height }} />
                        </Button>
                    </Stack>
                </Box>
            </Box>

        )
    }
    stackedContactInfo = () => {
        const section = this.state.site.sections.find(x => x.type === "contactinfo");
        if (section) {
            return (
                <Box key={"floating" + section.id} display="flex" sx={{ p: 1, background: this.state.site.floatingBackground ?? section.background }} >
                    <Stack sx={{ width: "100%", p:2 }}>
                        {section.officeHours &&
                            <>
                                <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                    {section.officeHours.title}
                                </Typography>
                                {section.officeHours.items.map(x => {
                                    return (
                                        <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".8rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                            {x}
                                        </Typography>
                                    )
                                }
                            )}
                            <br />
                            </>
                        }
                 
                        {section.address &&
                            <>
                                <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                    Address:
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                    {section.address.street}
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                    {section.address.city + ", " + section.address.state + "  " + section.address.zip}
                                </Typography>
                            </>
                        }
                        <br/>
                        <>
                            {section.phone &&
                                <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                    {"Phone: " + section.phone}
                                </Typography>
                            }
                            {section.email &&
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                                    <Typography variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                        {"Email: " + section.email}
                                    </Typography>
                                </Linkify>
                            }
                        </>
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    floatingContactInfoButton = () => {
       
        return (
            <>
                <Fab
                    sx={{
                        display: { xs: "none", md: "inline-flex"},
                        fontFamily: this.state.site.fontFamily,
                        position: "fixed", right: "24px", top: "120px",
                        background: this.state.site.buttonColor,
                        color: this.state.site.buttonColorText,
                        border: this.state.site.buttonBorder
                    }}
                    variant="extended"
                    aria-label="Contact Info"
                    onClick={this.handleClickFloatingContactButton}
                >
                    Contact Info         
                    <InfoIcon color="secondary" sx={{ ml: 1 }} />
                </Fab>
                <Popover
                    id="floatingcontactbutton"
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={() =>  this.setState({ anchorEl: null }) }
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {this.stackedContactInfo()}
                </Popover>
            </>
            )
    }

    contactInfo = (section) => {
        return (
            <Box key={section.id} display="flex" sx={{ background: section.background }} >
                <Stack sx={{ width: "100%" }}>
                    {section.title &&
                        <Typography variant={this.state.site.compact ? "h4" : "h5"} sx={{ fontFamily: this.state.site.fontFamily, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, textAlign: "center", mt:1  }}>
                            {section.title}
                        </Typography>
                    }
                    <Grid container spacing={3} sx={{ maxWidth: "1440px", mt:2, mx:"auto"}}>
                        {/*{section.officeHours &&*/}
                        {/*    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>*/}
                        {/*    <Stack sx={{ px: { xs: 2,  }, width: { xs: "100%", md: undefined }, m:"auto" }}>*/}
                        {/*            <Typography key="oh" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight:600, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*            {section.officeHours.title}*/}
                        {/*        </Typography>*/}
                        {/*        {section.officeHours.items.map(x => {*/}
                        {/*            return (*/}
                        {/*                <Typography key={x + "b"}  variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".8rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*                    {x}*/}
                        {/*                </Typography>*/}
                        {/*            ) }*/}
                        {/*        )}*/}

                        {/*        </Stack>*/}
                        {/*    </Grid>*/}
                        {/*}*/}
                        {/*{section.address &&*/}
                        {/*    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>*/}
                        {/*        <Stack sx={{ px: { xs: 2, }, m: "auto" }}>*/}
                        {/*            <Typography key="ab1" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*                Address:*/}
                        {/*            </Typography>*/}
                        {/*            <Typography key="ab2" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*            {section.address.street}*/}
                        {/*        </Typography>*/}
                        {/*            <Typography key="ab3" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*            {section.address.city + ", " + section.address.state + "  " + section.address.zip}*/}
                        {/*        </Typography>*/}
                        {/*        </Stack>*/}
                        {/*        </Grid>*/}
                        {/*}*/}
                        {/*<Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>*/}
                        {/*    <Stack sx={{ px: { xs: 2, }, m: "auto" }}>*/}
                        {/*    {section.phone &&*/}
                        {/*            <Typography key="ab4" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*            {"Phone: " + section.phone}*/}
                        {/*        </Typography>*/}
                        {/*    }*/}
                        {/*    {section.email &&*/}
                        {/*        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>*/}
                        {/*                <Typography key="ab5" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: ".9rem", color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>*/}
                        {/*                {"Email: " + section.email}*/}
                        {/*            </Typography>*/}
                        {/*        </Linkify>*/}
                        {/*    }*/}
                        {/*    </Stack>*/}
                        {/*    </Grid>*/}
                        {section.officeHours &&
                            <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                <Stack sx={{ px: { xs: 2, }, width: { xs: "100%", md: undefined }, m: "auto" }}>
                                    <Typography key="cisoh" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: { xs: "1rem", md: "1.1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                        {section.officeHours.title}
                                    </Typography>
                                    {section.officeHours.items.map(x => {
                                        return (
                                            <Typography key={x} variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {x}
                                            </Typography>
                                        )
                                    }
                                    )}

                                </Stack>
                            </Grid>
                        }
                        {section.flatlayout &&
                            <>
                                {
                                    section.address &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>

                                        <Typography key="ciradd" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                            {"Address: " + section.address.street + ", " + section.address.city + ", " + section.address.state + "  " + section.address.zip}
                                        </Typography>

                                    </Grid>
                                }
                                {
                                    section.phone &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                        <Typography key="cirpn" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                            {"Phone: " + section.phone}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    section.email &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                        <Linkify key="ciraelfy" componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key + "asdf"}> {decoratedText} </a>)}>
                                            <Typography key="cirae" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {"Email: " + section.email}
                                            </Typography>
                                        </Linkify>
                                    </Grid>
                                }
                            </>

                        }
                        {!section.flatlayout &&
                            <>
                                {section.address &&
                                    <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                        <Stack sx={{ px: { xs: 2, }, m: "auto" }}>
                                            <Typography key="ciradd" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontWeight: 600, fontSize: { xs: "1rem", md: "1.1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                Address:
                                            </Typography>
                                            <Typography key="ciradd2" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {section.address.street}
                                            </Typography>
                                            <Typography key="ciradd3" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {section.address.city + ", " + section.address.state + "  " + section.address.zip}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={4} sx={{ textAlign: "center", pr: { xs: 3, sm: undefined } }}>
                                    <Stack sx={{ px: { xs: 2, }, m: "auto" }}>
                                        {section.phone &&
                                            <Typography key="cirpn" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                {"Phone: " + section.phone}
                                            </Typography>
                                        }
                                        {section.email &&
                                            <Linkify key="ciraelfy" componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key + "asdf"}> {decoratedText} </a>)}>
                                                <Typography key="cirae" variant="body1" sx={{ fontFamily: this.state.site.fontFamily, fontSize: { xs: ".9rem", md: "1rem" }, color: theme => section.titleColor === "secondary" ? theme.palette.secondary.main : section.titleColor, }}>
                                                    {"Email: " + section.email}
                                                </Typography>
                                            </Linkify>
                                        }
                                    </Stack>
                                </Grid>

                            </>
                        }
                        </Grid>
                </Stack>
            </Box>
        )
    }
    footer = (section) => {
        return (
            <Box key={section.id} display="flex" sx={{ background: section.background }} >
                <Stack sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                        <Box display="flex" sx={{ maxWidth: "1440px", marginLeft: "auto", marginRight: "auto", p: 2 }}>
                            <img src={section.logo} alt={section.alt} style={{ maxHeight: section.logoHeight, width: section.partner ? "50%" : "100%", height: "auto", objectFit: "contain" }} />
                            {section.partner &&
                                <a style={{ margin: "auto", width: "50%", height: "auto", maxHeight: "90px" }} href={section.partner.href} target="_blank">
                                    <img src={section.partner.logo} alt={section.partner.alt} style={{ maxHeight: section.partner.logoHeight, width: "100%", height: "auto", objectFit: "contain" }} />
                                </a>
                            }
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ fontFamily: this.state.site.fontFamily, color:section.titleColor, textAlign: "center", mb: 1 }}>
                        <span>&copy; Copyright 2024 </span>{this.state.site.companyName}
                    </Typography>
                </Stack>
            </Box>
        )
    }

    content = () => {
        return (
            <>
                {this.meta()}
                <Stack
                    className="prettyScroll"
                    sx={{
                        height: "100vh",
                        fontFamily: this.state.site.fontFamily ?? "Public Sans, sans-serif",
                        pb: { xs: 8, md: 0 }
                    }}
                >
                    {this.state.site.sections.map((item, index) => {
                        if (item.display) {
                            switch (item.type) {
                                case "header":
                                    return this.header(item);
                                case "main":
                                    return this.main(item);
                                case "cards":
                                    return this.cards(item);
                                case "socials":
                                    return this.socials(item);
                                case "partner":
                                    return this.partner(item);
                                case "contactinfo":
                                    return this.contactInfo(item);
                                case "footer":
                                    return this.footer(item);
                                default:
                                    return null;
                            }
                        }
                        return null;
                    })}
                </Stack>
                {this.contactForm()}
                {this.login()}
                {this.viewerDocument() }
                {this.state.site.floatingContactButton && this.floatingContactButton()}
                {this.state.site.floatingContactInfoButton && this.floatingContactInfoButton()}
            </>
        );
    }

    contactUsButton = () => {
        return (
            <Box sx={{ textAlign: "center", padding: "48px" }}>
                <Button variant="contained" color="secondary" size="large" sx={{ fontFamily: this.state.site.fontFamily, borderRadius: 1.5, background: this.state.site.buttonColor}} onClick={() => this.setState({ showContactForm: true })}>
                    Contact us
                </Button>
            </Box>
        )
    }
    contactForm = () => {
        let recips = "";
        if (this.state.notificationRecipients) {
            for (var i = 0; i < this.state.notificationRecipients.length; i++) {
                recips += this.state.notificationRecipients[i].email + ";";
            }
        }
        return (
            <ContactForm
                dark={this.props.dark}
                webPageID={this.state.site.id}
                notify={this.state.site.notifyOnContact}
                notificationEmail={recips}
                notificationName={this.state.site.notificationName}
                site={this.state.site.pageTitle}
                show={this.state.showContactForm}
                title={"Contact " + this.state.site.pageTitle}
                hide={() => this.setState({ showContactForm: false })}
            />
        )
    } 
    siteNotFound = () => {
        return (
            <Box sx={{ height: "100vh", width: "100%", display: 'flex', justifyContent: "center" }}>
                <Typography variant="h3" sx={{ fontFamily: this.state.site.fontFamily, textAlign: "center", pt: 20, color: "#eee" }}>
                    Site Not Found
                </Typography>
            </Box>
        )
    }
    login = () => {
        if (this.state.site.clientPortal) {
            return (
                <ModalDialog {...this.props} show={this.state.showLogin}
                    padding="0px 8px"
                    handleClose={() => this.setState({ showLogin: false })}
                    fullWidth={true}
                    maxWidth="sm"
                    disableButtons={true}
                    noPad
                    prettyScroll
                    fullHeight
                    noUpperClose
                >
                    <Login
                        appIdentifier={this.props.siteID}
                        setUserInfo={this.props.setUserInfo}
                        setToken={this.props.setToken}
                        logo={this.state.site.clientPortal.loginLogo}
                        buttonColor={this.state.site.clientPortal.buttonColor}
                        accountRequestMessage={this.state.site.clientPortal.accountRequestMessage}
                    />
                </ModalDialog>

            )
        }
        return null;
    }
    viewerDocument = () => {
        if (this.state.showDocumentViewer) {
            return (
                <ModalDialog
                    dark={true}
                    show={this.state.showDocumentViewer}
                    handleClose={() => this.setState({ showDocumentViewer: false })}
                    fullWidth={true}
                    disableButtons
                    hideScroll={true}
                    noUpperClose
                    maxWidth="xl"
                    closeLabel="Close"
                    noSidePad
                    fullScreen
                    solid
                    fullHeight
                >
                    <DocumentViewer
                        dark={true}
                        fileName={this.state.docName}
                        document={this.state.docContent}
                        scale={this.state.width < 400 ? 0.5 : 1.6}
                        handleClose={() => this.setState({ showDocumentViewer: false })}
                        fullHeight
                        width={this.state.width}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    meta = () => {
        if (this.state.site) {
            return (

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.site.title}</title>
                    <meta name="description" content={this.state.site.meta.description} />
                    <meta property="og:title" content={this.state.site.title} />
                    <meta property="og:type" content="video.movie" />
                    <meta property="og:url" content={this.state.site.url} />
                    <meta property="og:image" content={this.state.site.logo} />
                    <meta name="twitter:title" content={this.state.site.title} />
                    <meta name="twitter:description" content={this.state.site.meta.description} />
                    <meta name="twitter:image" content={this.state.site.logo} />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
            )
        }
        return null;
    }
    loadingSpinner = () => {
        return (
            <Box sx={{ height: "100vh", width: "100%", display: 'flex', justifyContent: "center" }}>
                <Spin open={!this.state.site && !this.state.loadError} message="Loading..." />
            </Box>
        )
    }
    spacer = (y) => {
        return (<Box sx={{ height: { xs: 0, sm: y } }} />)
    }
    staticSpacer = (y) => {
        return (<Box sx={{ height: { xs: y } }} />)
    }
    floatingContactButton = () => {
        return (
            <Fab
                sx={{
                    display: { xs: "none", md: "inline-flex" },
                    fontFamily: this.state.site.fontFamily,
                    position: "fixed",
                    right: "24px",
                    top: "200px",
                    background: this.state.site.buttonColor, color: this.state.site.buttonColorText, border: this.state.site.buttonBorder
                }}
                variant="extended"
                aria-label="Contact Us"
                onClick={() => this.setState({ showContactForm: true })}
            >
                Message Us
                <MessageIcon color="secondary" sx={{ ml: 1 }} />
            </Fab>
            )
    }
    render() {
        if (this.state.site) {
            return this.content();
        } else {
            if (this.state.loadError) 
                return this.siteNotFound();           
        }
        return this.loadingSpinner();
    }
}

